import http from '@/utils/request'
import defaultSettings from '@/settings'

/**
   * 电子书分页查询
   * @param {查询条件} data
   */
export function getBookListWithPager(data) {
  return http.request({
    url: 'EBooks/FindWithPagerAsync',
    method: 'post',
    data: data,
    baseURL: defaultSettings.apiCMSUrl // 直接通过覆盖的方式
  })
}/**
   * 获取所有可用的教案
   */
export function getAllBookList() {
  return http.request({
    url: 'EBooks/GetAllEnable',
    method: 'get',
    baseURL: defaultSettings.apiCMSUrl // 直接通过覆盖的方式
  })
}
/**
   * 新增或修改保存电子书
   * @param data
   */
export function saveBook(data, url) {
  return http.request({
    url: url,
    method: 'post',
    data: data,
    baseURL: defaultSettings.apiCMSUrl // 直接通过覆盖的方式
  })
}
/**
   * 获取文章，通知公告详情
   * @param {Id} 文章，通知公告Id
   */
export function getBookDetail(id) {
  return http({
    url: 'EBooks/GetById',
    method: 'get',
    params: { id: id },
    baseURL: defaultSettings.apiCMSUrl // 直接通过覆盖的方式
  })
}
/**
   * 批量设置启用状态
   * @param {id集合} ids
   */
export function setBookEnable(data) {
  return http({
    url: 'EBooks/SetEnabledMarktBatchAsync',
    method: 'post',
    data: data,
    baseURL: defaultSettings.apiCMSUrl // 直接通过覆盖的方式
  })
}
/**
   * 批量软删除
   * @param {id集合} ids
   */
export function deleteSoftBook(data) {
  return http({
    url: 'EBooks/DeleteSoftBatchAsync',
    method: 'post',
    data: data,
    baseURL: defaultSettings.apiCMSUrl // 直接通过覆盖的方式
  })
}
/**
   * 批量删除
   * @param {id集合} ids
   */
export function deleteBook(data) {
  return http({
    url: 'EBooks/DeleteBatchAsync',
    method: 'delete',
    data: data,
    baseURL: defaultSettings.apiCMSUrl // 直接通过覆盖的方式
  })
}
/**
 * 上传文件
 * @returns
 */
export function UploadBookFile(formData) {
  return http.request({
    url: 'EBooks/UploadBook',
    method: 'post',
    data: formData,
    baseURL: defaultSettings.apiCMSUrl // 直接通过覆盖的方式
  })
}
/**
 * 获取电子书列表
 * @returns
 */
export function GetMyBookWithPager(formData) {
  return http.request({
    url: 'EBooks/FindMyBookWithPager',
    method: 'post',
    data: formData,
    baseURL: defaultSettings.apiCMSUrl // 直接通过覆盖的方式
  })
}
/**
 * 获取电子书Tocken
 * @returns
 */
export function GetBookTocken(formData) {
  return http.request({
    url: 'EBooks/GetBookTocken',
    method: 'post',
    data: formData,
    baseURL: defaultSettings.apiCMSUrl // 直接通过覆盖的方式
  })
}

