import defaultSettings from '@/settings'
import { createStore } from "vuex";
import { getToken, setToken, removeToken, getUserId, setUserId, getUserName, setUserName } from '@/utils/auth'
import { login, logout, refreshToken, getListMeunFuntionBymeunCode, sysConnect, getUserInfo } from '@/api/basebasic'

const getDefaultState = () => {
  return {
    token: getToken(),
    name: '',
    avatar: '',
    subSystem: [],
    activeSystemName: [],
    menus: [],
    roles: [],
    permissions: [],
    appId: defaultSettings.appId,
    appSecret: defaultSettings.appSecret,  
    loginUserId: '',
    loginUserName:''
  }
}

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState())
  },
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_APPID: (state, appId) => {
    state.appId = appId
  },
  SET_APPSECRET: (state, appSecret) => {
    state.appSecret = appSecret
  },    
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
  },
  SET_TEMPNAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_SUBSYSTEM: (state, subSystem) => {
    state.subSystem = subSystem
  },
  SET_ACTIVESYSTEMNAME: (state, activeSystemName) => {
    state.activeSystemName = activeSystemName
  },
  SET_MENUS: (state, menus) => {
    state.menus = menus
  },
  SET_PERMISSIONS: (state, permissions) => {
    state.permissions = permissions
  },
  SET_LOGINUSERID: (state, loginUserName) => {
    state.loginUserName = loginUserName
  },
  SET_LOGINUSERNAME: (state, loginUserId) => {
    state.loginUserId = loginUserId
  }  
}

const store = createStore({
  state: getDefaultState(),
  getters: {
    appId: state => state.appId,
    appSecret: state => state.appSecret
  },
  mutations: mutations,
  actions: {
    userlogin({ commit }, loginInfo) {
      // console.log(loginInfo)
      const { username, password, vcode, verifyCodeKey } = loginInfo
      return new Promise((resolve, reject) => {
        login({ username: username.trim(), password: password, vcode: vcode, vkey: verifyCodeKey, appId: loginInfo.appId, systemCode: loginInfo.systemCode, openId: loginInfo.openId, bindWx: loginInfo.bindWx}).then(response => {
          const data = response.ResData
          setToken(data.AccessToken)
          commit('SET_TOKEN', data.AccessToken)
          commit('SET_APPID', loginInfo.appId)
          commit('SET_LOGINUSERID', data.UserId)
          console.log('d1=' + data.UserId)
          setUserId(data.UserId)
          commit('SET_LOGINUSERNAME', data.Name)
          setUserName(data.Name)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },   
    loginByToken({ commit }, accessToken) {
      let uName = getUserName()
      let uId = getUserId()
      console.log('tocken=' + accessToken + 'uid=' + uId + 'uname=' + uName)

      return new Promise((resolve, reject) => {
        commit('SET_TOKEN', accessToken)
        commit('SET_APPID',  defaultSettings.appId)
        commit('SET_LOGINUSERID', uId)
        commit('SET_LOGINUSERNAME', uName)      
        resolve('ok')
      })
    }
  },
  modules: {},
});

export default store