<template>
  <div class="login">
    <div class="i-head">
      <div class="l">
        <img src="/images/logo.png" alt="" />
      </div>
      <div class="r">
        <div class="img">
          <img src="/images/tx.png" alt="" />
        </div>
        <div class="vip">
          <img src="/images/vip.png" alt="" />
        </div>
      </div>
    </div>
    <div class="i-main">
      <div class="i-d1">
        <div class="s-t">
          <span>基础课程</span>
        </div>
        <div class="box">
          <div class="swiper-container">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                v-for="item in baseBooks"
                :key="item.Id"
              >
                <div class="i1d">
                  <div class="l_img">
                    <img src="/images/i1.png" alt="" />
                  </div>
                  <div class="d2">{{ item.BookName }}</div>
                  <div class="d3">
                    <a class="left" @click="showEBook(item.Id)">
                      <div class="img">
                        <img src="/images/i2.png" alt="" />
                      </div>
                      <span>电子书</span>
                    </a>
                    <a class="right" @click="showRes(item.Id)">
                      <div class="img">
                        <img src="/images/i3.png" alt="" />
                      </div>
                      <span>资源库</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="i-d1">
        <div class="s-t">
          <span>拓展课程</span>
        </div>
        <div class="box">
          <div class="swiper-container">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                v-for="item in expandBooks"
                :key="item.Id"
              >
                <div class="i1d">
                  <div class="l_img">
                    <img src="/images/i1.png" alt="" />
                  </div>
                  <div class="d2">{{ item.BookName }}</div>
                  <div class="d3">
                    <a class="left" @click="showEBook(item.Id)">
                      <div class="img">
                        <img src="/images/i2.png" alt="" />
                      </div>
                      <span>电子书</span>
                    </a>
                    <a class="right" @click="showRes(item.Id)">
                      <div class="img">
                        <img src="/images/i3.png" alt="" />
                      </div>
                      <span>资源库</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="i-d1">
        <div class="s-t">
          <span>深度学习实验通讯</span>
        </div>
        <div class="box">
          <div class="swiper-container">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                v-for="item in periodicalBooks"
                :key="item.Id"
              >
                <div class="i1d">
                  <div class="l_img">
                    <img src="/images/i1.png" alt="" />
                  </div>
                  <div class="d2">{{ item.BookName }}</div>
                  <div class="d3">
                    <a class="left" @click="showEBook(item.Id)">
                      <div class="img">
                        <img src="/images/i2.png" alt="" />
                      </div>
                      <span>电子书</span>
                    </a>
                    <a class="right" @click="showRes(item.Id)">
                      <div class="img">
                        <img src="/images/i3.png" alt="" />
                      </div>
                      <span>资源库</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <div class="f-h"></div>
    <div class="footer">
      <router-link to="/Index" class="this">
        <div class="img">
          <img src="/images/f1.png" alt="" class="c1" />
          <img src="/images/f5.png" alt="" class="c2" />
        </div>
        <p>首页</p>
      </router-link>
      <router-link to="/EBooks">
        <div class="img">
          <img src="/images/f2.png" alt="" class="c1" />
          <img src="/images/f6.png" alt="" class="c2" />
        </div>
        <p>电子书</p>
      </router-link>
      <router-link to="/Res/0">
        <div class="img">
          <img src="/images/f3.png" alt="" class="c1" />
          <img src="/images/f7.png" alt="" class="c2" />
        </div>
        <p>资源库</p>
      </router-link>
      <router-link to="/MyInfo">
        <div class="img">
          <img src="/images/f4.png" alt="" class="c1" />
          <img src="/images/f8.png" alt="" class="c2" />
        </div>
        <p>我的</p>
      </router-link>
    </div>
  </div>
</template>

<script>
import { setToken } from "@/utils/auth";
import { getAllBookList, GetBookTocken } from "@/api/cms/ebooks";
import defaultSettings from "@/settings";

export default {
  name: "IndexView",
  data() {
    return {
      baseBooks: [],
      expandBooks: [],
      periodicalBooks: [],
      httpCourseUrl: defaultSettings.courseShowUrl,
    };
  },
  created() {
    this.getBooks();
  },
  methods: {
    getBooks() {
      getAllBookList().then((res) => {
        if (res.ErrCode === "0") {
          let bookCols = res.ResData;
          bookCols.forEach((element) => {
            if (element.SubTitle === "基础") {
              this.baseBooks.push(element);
            } else if (element.SubTitle === "拓展") {
              this.expandBooks.push(element);
            } else if (element.SubTitle === "通讯") {
              this.periodicalBooks.push(element);
            }
          });
        } else {
          this.$notify("没有权限读取该资源");
        }
      });
    },
    showEBook: function (bookId) {
      if (bookId.indexOf("_0") !== -1) {
        this.$notify("此书籍正在快马加鞭编辑中，敬请期待！！！", "提示");
        return false;
      }

      var postdata = {
        Id: bookId,
      };
      GetBookTocken(postdata).then((res) => {
        if (res.ErrCode === "0") {
          var url =
            this.httpCourseUrl + bookId + "/mobile/index.html?q=" + res.ResData;
          window.location.href = url;
          // window.open(url);
        } else {
          this.$alert("抱歉，您没有权限阅读该资源", "提示");
        }
      });
      return false;
    },
    showRes: function (bookId) {
      if (bookId.indexOf("_0") !== -1) {
        this.$alert("此书籍正在快马加鞭编辑中，敬请期待！！！", "提示");
        return false;
      }
      this.$router.push({
        name: "Res",
        params: { id: bookId },
      });
      return false;
    },
  },
};
</script>

<style scoped>
input,
button,
textarea {
  color: #999;
}
.swiper-slide {
  width: 30%;
  float: left;
  margin-right: 10px;
}
.i-d1 {
  clear: both;
}
</style>