import http from '@/utils/request'
import defaultSettings from '@/settings'

/**
   * 案例分页查询
   * @param {查询条件} data
   */
export function getResListWithPager(data) {
  return http.request({
    url: 'Res/FindWithPagerAsync',
    method: 'post',
    data: data,
    baseURL: defaultSettings.apiCMSUrl // 直接通过覆盖的方式
  })
}

/**
   * 电子书对应资源分页查询
   * @param {查询条件} data
   */
export function getBookResWithPager(data) {
  return http.request({
    url: 'Res/FindBookRes',
    method: 'post',
    data: data,
    baseURL: defaultSettings.apiCMSUrl // 直接通过覆盖的方式
  })
}
/**
   * 获取所有可用的教案
   */
export function getAllResList() {
  return http.request({
    url: 'Res/GetAllEnable',
    method: 'get',
    baseURL: defaultSettings.apiCMSUrl // 直接通过覆盖的方式
  })
}
/**
   * 获取资源文件
   */
export function getResFiles(data) {
  return http.request({
    url: 'Res/GetResFiles',
    method: 'post',
    data: data,
    baseURL: defaultSettings.apiCMSUrl // 直接通过覆盖的方式
  })
}
/**
   * 新增或修改保存文章，通知公告
   * @param data
   */
export function saveRes(data, url) {
  return http.request({
    url: url,
    method: 'post',
    data: data,
    baseURL: defaultSettings.apiCMSUrl // 直接通过覆盖的方式
  })
}
/**
   * 获取案例详情
   * @param {Id} 案例Id
   */
export function getResDetail(id) {
  return http({
    url: 'Res/GetById',
    method: 'get',
    params: { id: id },
    baseURL: defaultSettings.apiCMSUrl // 直接通过覆盖的方式
  })
}
/**
   * 批量设置启用状态
   * @param {id集合} ids
   */
export function setResEnable(data) {
  return http({
    url: 'Res/SetEnabledMarktBatchAsync',
    method: 'post',
    data: data,
    baseURL: defaultSettings.apiCMSUrl // 直接通过覆盖的方式
  })
}
/**
   * 批量软删除
   * @param {id集合} ids
   */
export function deleteSoftRes(data) {
  return http({
    url: 'Res/DeleteSoftBatchAsync',
    method: 'post',
    data: data,
    baseURL: defaultSettings.apiCMSUrl // 直接通过覆盖的方式
  })
}

/**
   * 批量删除
   * @param {id集合} ids
   */
export function deleteRes(data) {
  return http({
    url: 'Res/DeleteBatchAsync',
    method: 'delete',
    data: data,
    baseURL: defaultSettings.apiCMSUrl // 直接通过覆盖的方式
  })
}
