module.exports = {
  title: '深度学习新样态智慧云平台',
  /**
   * 侧边栏主题 深色主题theme-dark，浅色主题theme-light
   */
  sideTheme: 'theme-dark',
  /**
   * 是否系统布局配置
   */
  showSettings: false,

  /**
   * 是否显示 tagsView
   */
  tagsView: true,
  /**
   *是否固定头部
   */
  fixedHeader: false,
  /**
   * 是否显示侧边Logo
   */
  sidebarLogo: true,

  /**
   * 应用Id
   */
  appId: 'system',
  /**
   * 应用密钥
   */
  appSecret: '87135AB0160F706D8B47F06BDABA6FC6',
  /**
   * 子系统
   */
  subSystem: {},
  /**
   * 当前访问系统代码
   */
  activeSystemCode: 'openauth',
  /**
   * 当前访问系统名称
   */
  activeSystemName: '',
  /**
   * 动态可访问路由
   */
  addRouters: {},
  /**
   * 公众号appid
   */
  wxAppId: 'wx6f1c0749214381a0',
  wxCallBackUrl:'http://m.pbl.gd.cn/#/wxlogin',

  // apiHostUrl: 'http://localhost:5000/api/', // 基础接口
  // apiSecurityUrl: 'http://localhost:5000/api/Security/', // 权限管理系统接口
  // apiCMSUrl: 'http://localhost:5000/api/CMS/', // 文章
  // apiCourseUrl: 'http://localhost:5000/api/Course/', // 教程
  // courseUploadUrl: 'http://localhost:5000/api/Files/UploadBook', // 教程
  // courseShowUrl: 'http://localhost:5000/HnCourse/',
  // fileUrl: 'http://localhost:5000/', // 文件访问路径
  // fileUploadUrl: 'http://localhost:5000/api/Files/Upload', // 文件上传路径

  // apiHostUrl: 'https://localhost:44364/api/', // 基础接口
  // apiSecurityUrl: 'https://localhost:44364/api/Security/', // 权限管理系统接口
  // apiCMSUrl: 'https://localhost:44364/api/CMS/', // 文章
  // apiCourseUrl: 'https://localhost:44364/api/Course/', // 教程
  // courseUploadUrl: 'https://localhost:44364/api/Files/UploadBook', // 教程
  // courseShowUrl: 'https://localhost:44364/HnCourse/',
  // fileUrl: 'https://localhost:44364/', // 文件访问路径
  // fileUploadUrl: 'https://localhost:44364/api/Files/Upload', // 文件上传路径

  apiHostUrl: 'http://e.pbl.gd.cn:5000/api/', // 基础接口
  apiSecurityUrl: 'http://e.pbl.gd.cn:5000/api/Security/', // 权限管理系统接口
  apiCMSUrl: 'http://e.pbl.gd.cn:5000/api/CMS/', // 文章
  apiCourseUrl: 'http://e.pbl.gd.cn:5000/api/Course/', // 教程
  courseUploadUrl: 'http://e.pbl.gd.cn:5000/api/Files/UploadBook', // 教程
  courseShowUrl: 'http://e.pbl.gd.cn:5000/HnCourse/',
  fileUrl: 'http://e.pbl.gd.cn:5000/', // 文件访问路径
  fileUploadUrl: 'http://e.pbl.gd.cn:5000/api/Files/Upload' // 文件上传路径
}
