<template>
  <div class="ResList">
    <div class="head">
      <div class="l">
        <img src="/images/logo2.png" alt="" />
      </div>
      <div class="r2">
        <span @click="showBooks"> 电子书 </span>
        <input
          type="text"
          placeholder="关键字搜索资源"
          v-model="searchform.keywords"
          @keyup.enter="searchEvent"
        />
        <button @click="searchEvent"></button>
        <div class="box" v-show="showBookBox" style="width: 120px">
          <p v-for="item in bookList" :key="item.Id">{{ item.BookName }}}</p>
        </div>
      </div>
    </div>
    <div class="zyk-d">
      <ul>
        <li
          v-for="item in tableData"
          :key="item.Id"
          @click="ShowDetail(item.Id)"
        >
          <div class="l_img">
            <img src="/images/zyk1.png" alt="" />
          </div>
          <p>{{ item.ResName }}</p>
        </li>
      </ul>
    </div>
    <div class="f-h"></div>
    <div class="footer">
      <router-link to="/">
        <div class="img">
          <img src="/images/f1.png" alt="" class="c1" />
          <img src="/images/f5.png" alt="" class="c2" />
        </div>
        <p>首页</p>
      </router-link>
      <router-link to="/EBooks">
        <div class="img">
          <img src="/images/f2.png" alt="" class="c1" />
          <img src="/images/f6.png" alt="" class="c2" />
        </div>
        <p>电子书</p>
      </router-link>
      <router-link to="/Res/0" class="this">
        <div class="img">
          <img src="/images/f3.png" alt="" class="c1" />
          <img src="/images/f7.png" alt="" class="c2" />
        </div>
        <p>资源库</p>
      </router-link>
      <router-link to="/MyInfo">
        <div class="img">
          <img src="/images/f4.png" alt="" class="c1" />
          <img src="/images/f8.png" alt="" class="c2" />
        </div>
        <p>我的</p>
      </router-link>
    </div>
  </div>
</template>

<script>
import { getBookResWithPager, getResFiles } from "@/api/cms/res";
import { getAllBookList } from "@/api/cms/ebooks";
import { getToken } from "@/utils/auth";
import defaultSettings from "@/settings";

export default {
  name: "ResView",
  data() {
    return {
      searchform: {
        keywords: "",
        BookId: "",
      },
      tableData: [],
      bookList: [],
      resFiles: [],
      loading: false,
      dataloading: false,
      tableloading: true,
      showBookBox: false,
      pagination: {
        currentPage: 1,
        pagesize: 50,
        pageTotal: 0,
      },
      sortableData: {
        order: "desc",
        sort: "CreatorTime",
      },
      httpCourseUrl: defaultSettings.courseShowUrl,
      initBooId: 0,
    };
  },
  created() {
    this.pagination.currentPage = 1;
    this.searchform.BookId = this.$route.params.id;
    // this.headers = { Authorization: "Bearer " + (getToken() || "") };
    this.loadData();
    getAllBookList().then((res) => {
      this.bookList = res.ResData;
      // if (this.initBooId !== "") {
      //   this.searchform.BookId = this.initBooId;
      // }
    });
  },
  methods: {
    loadData: function () {
      this.tableloading = true;
      var seachdata = {
        CurrenetPageIndex: this.pagination.currentPage,
        PageSize: this.pagination.pagesize,
        Keywords: this.searchform.keywords,
        Order: this.sortableData.order,
        Sort: this.sortableData.sort,
        Filter: this.searchform,
      };
      getBookResWithPager(seachdata).then((res) => {
        this.tableData = res.ResData.Items;
        this.pagination.pageTotal = res.ResData.TotalItems;
        this.tableloading = false;
      });
    },
    ShowDetail: function (id) {
      this.$router.push({
        name: "ResDetail",
        params: { id: id, bid: this.searchform.BookId },
      });
    },
    searchEvent: function () {
      this.loadData();
    },
    showBooks: function () {
      console.log("showBooks");
      this.showBookBox = true;
    },
  },
};
</script>

<style scoped>
</style>