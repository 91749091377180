<template>
  <div class="MyDetail">
    <div class="head head2">
      <a href="" class="b b2"> </a>
      <div class="t t2">我的</div>
    </div>
    <div class="wd-bg">
      <div class="wd-d1">
        <div class="d1">
          <div class="img">
            <img src="images/tx.png" alt="" />
          </div>
          <div class="vip">
            <img src="images/vip.png" alt="" />
          </div>
        </div>
        <div class="d2">
          {{ curUserInfo.RealName }}
        </div>
        <div class="d3">
          {{ curUserInfo.MobilePhone }}
        </div>
      </div>
      <div class="wd-d2">
        <ul>
          <li>
            <a href="">
              <i><img src="images/wd1.png" alt="" /></i> <span>修改密码</span>
              <div class="m"><img src="images/b.png" alt="" /></div>
            </a>
          </li>
          <li @click="logout">
            <i><img src="images/f4.png" alt="" /></i> <span>退出</span>
            <div class="m"><img src="images/b.png" alt="" /></div>
          </li>
          <!-- <li>
                    <a href="">
                        <i><img src="images/wd2.png" alt=""></i> <span>设置</span> <div class="m"><img src="images/b.png" alt=""></div>
                    </a>
                </li>
                <li>
                    <a href="">
                        <i><img src="images/wd3.png" alt=""></i> <span>联系我们</span> <div class="m"><img src="images/b.png" alt=""></div>
                    </a>
                </li> -->
        </ul>
      </div>
    </div>
    <div class="f-h"></div>
    <div class="footer">
      <router-link to="/">
        <div class="img">
          <img src="/images/f1.png" alt="" class="c1" />
          <img src="/images/f5.png" alt="" class="c2" />
        </div>
        <p>首页</p>
      </router-link>
      <router-link to="/EBooks">
        <div class="img">
          <img src="/images/f2.png" alt="" class="c1" />
          <img src="/images/f6.png" alt="" class="c2" />
        </div>
        <p>电子书</p>
      </router-link>
      <router-link to="/Res/0">
        <div class="img">
          <img src="/images/f3.png" alt="" class="c1" />
          <img src="/images/f7.png" alt="" class="c2" />
        </div>
        <p>资源库</p>
      </router-link>
      <router-link to="/MyInfo" class="this">
        <div class="img">
          <img src="/images/f4.png" alt="" class="c1" />
          <img src="/images/f8.png" alt="" class="c2" />
        </div>
        <p>我的</p>
      </router-link>
    </div>
  </div>
</template>

<script>
import { GetUserInfo } from "@/api/security/userservice";
import { getToken, removeToken } from "@/utils/auth";
import defaultSettings from "@/settings";

export default {
  name: "MyInfo",
  data() {
    return {
      curUserInfo: {},
    };
  },
  created() {
    GetUserInfo().then((res) => {
      this.curUserInfo = res.ResData;
    });
  },
  methods: {
    logout: function () {
      removeToken();
      location.href = "/";
    },
  },
};
</script>

<style scoped>
</style>