<template>
  <div class="login">
    <div class="head head2">
      <a href="" class="c"> </a>
      <a class="m"> 登录 </a>
    </div>
    <div class="wd-bg">
      <div class="dl-d1">
        <div class="t">
          <img src="/images/logo.png" alt="" />
        </div>
        <form>
          <ul>
            <li>
              <span>
                <img src="/images/dl1.png" alt="" />
              </span>
              <input
                type="text"
                placeholder="请输入您的账号"
                name="username"
                v-model="loginForm.username"
              />
            </li>
            <li>
              <span>
                <img src="/images/dl2.png" alt="" />
              </span>
              <input
                type="password"
                placeholder="请输入您的密码"
                name="password"
                v-model="loginForm.password"
              />
            </li>
            <!-- <li>
                        <span>
                            <img src="/images/dl1.png" alt="">
                        </span>
                        <input type="text" placeholder="请输入验证码" name="vcode" v-model="loginForm.vcode">
                    </li>                     -->
            <button type="button" @click="handleLogin" style="color: #fff">
              登录
            </button>
          </ul>
        </form>
        <div class="btn">
          <!-- <a href="">忘记密码</a>
                <a href="">立即注册</a> -->
        </div>
      </div>
      <div class="dl-d2">
        <div class="d1">其他登录方式</div>
        <a :href="wxLoginUrl" class="d2">
          <img src="/images/wx.png" alt="" />
        </a>
      </div>
    </div>
    <div class="f-h"></div>
  </div>
</template>

<script>
import { setToken, getCookieToken, getUserId } from "@/utils/auth";
import {
  getToken,
  getVerifyCode,
  getSysSetting,
  checkUserValid,
} from "@/api/basebasic";
import defaultSettings from "@/settings";

export default {
  name: "LoginView",
  data() {
    return {
      loginForm: {
        username: "",
        password: "",
        vcode: "",
        verifyCodeKey: "",
        appId: defaultSettings.appId,
        systemCode: defaultSettings.activeSystemCode,
        openId: "",
        bindWx: 0,
      },
      loading: false,
      wxLoginUrl:
        "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
        defaultSettings.wxAppId +
        "&redirect_uri=" +
        encodeURIComponent(defaultSettings.wxCallBackUrl) +
        "&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect",
    };
  },
  created() {
    this.loadToken();
    getSysSetting().then((res) => {});
    this.getLoginVerifyCode();
  },
  methods: {
    loadToken() {
      let tk = getCookieToken();
      let uid = getUserId();
      if (
        tk !== null &&
        tk !== "" &&
        tk !== undefined &&
        uid !== null &&
        uid !== "" &&
        uid !== undefined
      ) {
        checkUserValid({ uid: uid }).then((res) => {
          if (res.ErrCode === "0") {
            this.$store
              .dispatch("loginByToken", tk)
              .then((res) => {
                let redirect = this.$router.currentRoute.value.query.redirect;
                if (
                  redirect !== null &&
                  redirect !== "" &&
                  redirect !== undefined
                ) {
                  this.$router.push(redirect);
                } else {
                  this.$router.push("/");
                }
              })
              .catch((res) => {
                console.log(res);
                // this.loading = false;
              });
          } else {
            console.log("offline");
          }
        });
      } else {
        // getToken().then((response) => {
        //   setToken(response.ResData.AccessToken);
        //   this.isShow = true;
        // });
      }
    },
    handleLogin() {
      if (this.loginForm.username.length == 0) {
        this.$notify("账号不能为空");
        return false;
      }

      if (this.loginForm.password.length == 0) {
        this.$notify("密码不能为空");
        return false;
      }

      this.$store
        .dispatch("userlogin", this.loginForm)
        .then((res) => {
          this.$router.push("/");
          // console.log(this.$router);
          this.loading = false;
        })
        .catch((res) => {
          this.loading = false;
        });
      return false;
    },
    async getLoginVerifyCode() {
      this.loginForm.vcode = "";
      this.loginForm.verifyCodeKey = "33J#X8773";
    },
    getQueryString: function (name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      var r = window.location.search.substr(1).match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    },
  },
};
</script>

<style scoped>
input,
button,
textarea {
  color: #999;
}
</style>