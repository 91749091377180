<template>
  <div class="BookList">
    <div class="head">
      <div class="l">
        <img src="/images/logo2.png" alt="" />
      </div>
      <div class="r2 r3">
        <button></button
        ><input
          type="text"
          placeholder="关键字搜索课程"
          v-model="searchform.keywords"
          @keyup.enter="searchEvent"
        />
      </div>
    </div>
    <div class="dzs-d">
      <ul>
        <li v-for="item in bookCols" :key="item.Id" @click="showEBook(item.Id)">
          <div class="l_img">
            <img :src="item.ImgUrl" alt="" />
          </div>
          <p>{{ item.BookName }}</p>
        </li>
      </ul>
    </div>
    <div class="f-h"></div>
    <div class="footer">
      <router-link to="/">
        <div class="img">
          <img src="/images/f1.png" alt="" class="c1" />
          <img src="/images/f5.png" alt="" class="c2" />
        </div>
        <p>首页</p>
      </router-link>
      <router-link to="/EBooks" class="this">
        <div class="img">
          <img src="/images/f2.png" alt="" class="c1" />
          <img src="/images/f6.png" alt="" class="c2" />
        </div>
        <p>电子书</p>
      </router-link>
      <router-link to="/Res/0">
        <div class="img">
          <img src="/images/f3.png" alt="" class="c1" />
          <img src="/images/f7.png" alt="" class="c2" />
        </div>
        <p>资源库</p>
      </router-link>
      <router-link to="/MyInfo">
        <div class="img">
          <img src="/images/f4.png" alt="" class="c1" />
          <img src="/images/f8.png" alt="" class="c2" />
        </div>
        <p>我的</p>
      </router-link>
    </div>
  </div>
</template>

<script>
import { GetMyBookWithPager, GetBookTocken } from "@/api/cms/ebooks";
import { getToken } from "@/utils/auth";
import defaultSettings from "@/settings";

export default {
  name: "EBookView",
  components: {},
  data() {
    return {
      searchform: {
        keywords: "",
        grade: "",
        subject: "",
      },
      bookCols: [],
      loading: false,
      dataloading: false,
      pagination: {
        currentPage: 1,
        pagesize: 50,
        pageTotal: 0,
      },
      sortableData: {
        order: "desc",
        sort: "CreatorTime",
      },
      httpCourseUrl: defaultSettings.courseShowUrl,
    };
  },
  created() {
    this.pagination.currentPage = 1;
    // this.headers = { Authorization: "Bearer " + (getToken() || "") };
    this.loadData();
    // this.loadBtnFunc = JSON.parse(localStorage.getItem('yueboncurrentfuns'))
  },
  methods: {
    loadData: function () {
      this.dataloading = true;
      var filter = {
        Grade: this.searchform.grade,
        Subject: this.searchform.subject,
      };
      var seachdata = {
        CurrenetPageIndex: this.pagination.currentPage,
        PageSize: this.pagination.pagesize,
        Keywords: this.searchform.keywords,
        Filter: filter,
        Order: this.sortableData.order,
        Sort: this.sortableData.sort,
      };
      GetMyBookWithPager(seachdata).then((res) => {
        this.bookCols = res.ResData.Result.Items;
        this.bookCols.forEach((element) => {
          if (element.ImgUrl === "") {
            element.ImgUrl = defaultSettings.fileUrl + "/upload/blank.png";
          } else {
            element.ImgUrl = defaultSettings.fileUrl + element.ImgUrl;
          }
        });

        this.pagination.pageTotal = res.ResData.TotalItems;
        this.dataloading = false;
      });
    },
    showEBook: function (bookId) {
      console.log(bookId);
      var postdata = {
        Id: bookId,
      };
      GetBookTocken(postdata).then((res) => {
        if (res.ErrCode === "0") {
          var url =
            this.httpCourseUrl + bookId + "/mobile/index.html?q=" + res.ResData;
          window.location.href = url;
          // window.open(url);
        } else {
          this.$notify("没有权限读取该资源");
        }
      });
    },
    searchEvent: function () {
      this.loadData();
    },
  },
};
</script>

<style scoped>
</style>