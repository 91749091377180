<template>
  <div class="login">
    <div class="head head2">
      <a href="" class="c"> </a>
      <a class="m"> 登录 </a>
    </div>
    <div class="wd-bg">
      <div class="dl-d1">
        <div class="t">
          <img src="/images/logo.png" alt="" />
        </div>
        <form>
          <ul>
            <li>
              <span>
                <img src="/images/dl1.png" alt="" />
              </span>
              <input
                type="text"
                placeholder="请输入您的账号"
                name="username"
                v-model="loginForm.username"
              />
            </li>
            <li>
              <span>
                <img src="/images/dl2.png" alt="" />
              </span>
              <input
                type="password"
                placeholder="请输入您的密码"
                name="password"
                v-model="loginForm.password"
              />
            </li>
            <!-- <li>
                        <span>
                            <img src="../assets/images/dl1.png" alt="">
                        </span>
                        <input type="text" placeholder="请输入验证码" name="vcode" v-model="loginForm.vcode">
                    </li>                     -->
            <button type="button" @click="handleLogin">登录</button>
          </ul>
        </form>
        <div class="btn">
          <!-- <a href="">忘记密码</a>
                <a href="">立即注册</a> -->
        </div>
      </div>
      <!-- <div class="dl-d2">
            <div class="d1">
                其他登录方式
            </div>
            <a href="" class="d2">
                <img src="../assets/images/wx.png" alt="">
            </a>
        </div> -->
    </div>
    <div class="f-h"></div>
  </div>
</template>

<script>
import { setToken } from "@/utils/auth";
import {
  getToken,
  getVerifyCode,
  getSysSetting,
  getWxUnionId,
} from "@/api/basebasic";
import defaultSettings from "@/settings";

export default {
  name: "WxLoginView",
  data() {
    return {
      loginForm: {
        username: "",
        password: "",
        vcode: "",
        verifyCodeKey: "",
        appId: defaultSettings.appId,
        systemCode: defaultSettings.activeSystemCode,
        openId: "",
        bindWx: 0,
        agree: [],
      },
      loading: false,
    };
  },
  created() {
    this.loadToken();
    this.getLoginVerifyCode();
    this.getWxId();
  },
  methods: {
    handleLogin() {
      // console.log(this.loginForm)
      this.$store
        .dispatch("userlogin", this.loginForm)
        .then((res) => {
          // this.$router.push({
          //   path: this.redirect || '/',
          //   query: this.otherQuery,
          // })
          this.$router.push("/");
          this.loading = false;
        })
        .catch((res) => {
          this.loading = false;
        });
      return false;
    },
    loadToken() {
      getToken().then((response) => {
        setToken(response.ResData.AccessToken);
        getSysSetting().then((res) => {});
        this.isShow = true;
      });
    },
    async getLoginVerifyCode() {
      this.loginForm.vcode = "";
      //const res = await getVerifyCode()
      //if (res.Success) {
      this.loginForm.verifyCodeKey = "33J#X8773";
      //}
    },
    getWxId() {
      var code = this.getQueryString("code");
      var state = this.getQueryString("state");
      // this.loginForm.openId = "oIThLs_q9d8Gq0iolHRTcIASsXgQ";
      // this.loginByOpenId();
      // this.loginForm.bindWx = 0;
      // console.log(code);
      if (code !== "") {
        getWxUnionId(code, state).then((response) => {
          if (response.ErrCode === "40003") {
            console.log("error code");
          } else if (response.ErrCode === "0") {
            this.loginForm.openId = response.ResData.openId;
            if (response.ResData.binded === 1) {
              this.loginByOpenId();
              this.loginForm.bindWx = 0;
            } else {
              this.$notify("您未绑定微信号，请用账号密码登录绑定");
              // this.pageLoading.close()
              this.loginForm.bindWx = 1;
              this.loading = false;
              this.isShow = true;
            }
          }
        });
      }
    },
    loginByOpenId() {
      this.$store
        .dispatch("userlogin", this.loginForm)
        .then((res) => {
          this.$router.push("/");
          this.loading = false;
        })
        .catch((res) => {
          this.loading = false;
        });
    },
    getQueryString: function (name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      var r = window.location.search.substr(1).match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    },
  },
};
</script>

<style scoped>
input,
button,
textarea {
  color: #999;
}
</style>