import { createRouter, createWebHashHistory } from 'vue-router'
import store from '@/store'
import { getToken } from '@/utils/auth' // get token from cookie

import IndexView from '../views/IndexView.vue'
import LoginView from '../views/LoginView.vue'
import EBookView from '../views/EBooksView.vue'
import EBookDetailView from '../views/EBookDetailView.vue'
import ResView from '../views/ResView.vue'
import ResDetailView from '../views/ResDetailView.vue'
import MyInfoView from '../views/MyInfoView.vue'
import WxLoginView from '../views/WxLoginView.vue'

const routes = [
  {
    path: '/',
    name: 'Index',
    component: IndexView
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView
  },
  {
    path: '/wxlogin',
    name: 'Wxlogin',
    component: WxLoginView
  },
  {
    path: '/Res/:id',
    name: 'Res',
    component: ResView
  },
  {
    path: '/ResDetail/:id:bid',
    name: 'ResDetail',
    component: ResDetailView
  },
  {
    path: '/MyInfo',
    name: 'MyInfo',
    component: MyInfoView
  },
  {
    path: '/EBooks',
    name: 'EBooks',
    component: EBookView
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/EBookDetail/:code',
    name: 'EBookDetail',
    component: EBookDetailView
  },  
]

const router = createRouter({
  history: createWebHashHistory(),
  routes: routes
})

const whiteList = ['/login', '/register','/wxlogin'] // no redirect whitelist
router.beforeEach(async (to, from, next) => {
  // console.log(to)
  const hasToken = getToken()
  if (hasToken) {
    if (to.path === '/login') {
      next()
    } else if (to.path === '/register') {
      next()
    } else if (to.path === '/wxlogin') {
      next()
    } else {
      // console.log('JJJ=' + store.state.loginUserId)
      try {
        if (store.state.loginUserId === '') {
          next(`/login?redirect=${to.path}`)

          // await store.dispatch('user/getUserInfo').then(res => {
          //   if (res.Success) {
          //     store.dispatch('settings/loadUserSettingTheme', res.ResData.UserTheme).then(res => { })
          //     store.dispatch('GenerateRoutes', store.getters.menus).then(accessRoutes => {
          //       // 根据roles权限生成可访问的路由表
          //       router.addRoutes(accessRoutes) // 动态添加可访问路由表
          //       next({ ...to, replace: true }) // hack方法 确保addRoutes已完成
          //     })
          //   }
          // }).catch(err => {
          //   Message.error({
          //     message: err || '出现错误，请稍后再试'
          //   })
          //   store.dispatch('user/logout').then(res => {
          //     console.log('logout:' + JSON.stringify(res))
          //     // Message.error(err)
          //     next({ path: '/' })
          //   })
          // })
        } else {
          next()
        }
      } catch (error) {
        await store.dispatch('resetToken')
        // Message.error({
        //   message: error || '出现错误，请稍后再试'
        // })
        console.log(error)
        next(`/login?redirect=${to.path}`)
      }
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next()
    } else {
      console.log('getTock')
      next(`/login?redirect=${to.path}`)
    }
  }
})

export default router
