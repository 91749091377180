<template>
  <div class="ResDetail">
    <div class="head">
      <a @click="toRes" class="b"></a>
      <div class="t">{{ currRes.ResName }}</div>
    </div>
  </div>
</template>

<script>
import { getResDetail, getResFiles } from "@/api/cms/res";
import { getToken } from "@/utils/auth";
import defaultSettings from "@/settings";

export default {
  name: "ResDetail",
  data() {
    return {
      currentId: 0,
      currRes: {},
      bookId: 0,
      resFileList: [],
      loading: false,
      dataloading: false,
      tableloading: true,
      headers: [],
      sortableData: {
        order: "desc",
        sort: "CreatorTime",
      },
      httpCourseUrl: defaultSettings.courseShowUrl,
    };
  },
  created() {
    this.currentId = this.$route.params.id;
    this.bookId = this.$route.params.bid;
    // console.log("currentId=" + this.currentId);
    // console.log("bookid=" + this.$route.params.bid);
    this.loadData();
    this.headers = { Authorization: "Bearer " + (getToken() || "") };
  },
  methods: {
    loadData: function () {
      getResDetail(this.currentId).then((res) => {
        var cols = res.ResData.BookCols;
        if (cols !== null) {
          cols.forEach((ele) => {
            let resType = ele.value.toLowerCase();
            let img = "";
            if (resType.indexOf("pdf") != -1) {
              img = "/images/zyk2.png";
            } else if (
              resType.indexOf("doc") != -1 ||
              resType.indexOf("docx") != -1
            ) {
              img = "/images/zyk3.png";
            } else if (
              resType.indexOf("xls") != -1 ||
              resType.indexOf("xlsx") != -1
            ) {
              img = "/images/zyk3.png";
            } else if (resType.indexOf("ppt") != -1) {
              img = "/images/zyk4.png";
            } else if (resType.indexOf("mp4") != -1) {
              img = "/images/zyk5.png";
            } else if (
              resType.indexOf("jpg") != -1 ||
              resType.indexOf("png") != -1 ||
              resType.indexOf("jpeg") != -1
            ) {
              img = "/images/zyk6.png";
            }
            this.resFileList.push({
              name: ele.name,
              url: defaultSettings.fileUrl + ele.value,
              id: ele.key,
              img: img,
            });
          });
        }
        this.currRes = res.ResData;
        // this.$refs.editorDescription.setContent(res.ResData.Description)
      });
    },
    showFile(type, url) {
      if (type === "10") {
        this.$notify("请开通VIP，获取下载权限");
      } else {
        window.open(url);
      }
    },
    toRes: function () {
      this.$router.push("/Res/" + this.bookId);
    },
  },
};
</script>

<style scoped>
</style>